@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap);
body.Roboto {
  font-family: 'Roboto', sans-serif;
}
body.Inter {
  font-family: 'Inter', sans-serif;
}
body.poppins {
  font-family: 'Poppins', sans-serif;
}
