/* You can add global styles to this file, and also import other style files */
/**======================================================================
=========================================================================
Template Name: DashboardKit - Most Complete Bootstrap Admin Template
Author: Phoenixcoded
Support: support@phoenixcoded.net
File: style.css
=========================================================================
=================================================================================== */

// main framework
@import 'scss/settings/preset-variables';
@import 'node_modules/bootstrap/scss/functions';
@import 'node_modules/bootstrap/scss/variables';
$pc-body-bg: map-get($preset-colors, 'preset-7', 'body');
$pc-blue: map-get($preset-colors, 'preset-7', 'primary');
$pc-secondary: map-get($preset-colors, 'preset-7', 'secondary');
@import 'scss/settings/custom-variables';
@import 'scss/settings/theme-variables';
@import 'scss/settings/general';
@import 'scss/fonts/themify-icons/themify-icons.css';

@import 'node_modules/bootstrap/scss/bootstrap';
// main framework
@import 'scss/themes/generic';
@import 'scss/themes/general';

// Layouts
@import 'scss/themes/layouts/layouts';
@import 'scss/themes/layout/layout';

// Componants
@import 'scss/themes/components/components';

//pages
@import 'scss/themes/pages/page';

@import 'scss/themes/customizer';
@import 'scss/style-preset.scss';
// @import "scss/landing.scss";

// fonts
@import url('scss/fonts/berry/berry-icons.css'); /** custom font icons - berry **/
@import 'scss/fonts/feather.css';
@import 'scss/fonts/tabler-icons.min.css';

[class='Roboto berry-rtl'] {
  @import 'scss/themes/layout/style-rtl-utility';
  @import 'node_modules/bootstrap/scss/utilities/api';
}
